import { lazy } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Navigate, Route } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';


/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const TableauDeBord = Loadable(lazy(() => import('../views/Acceuil/TableauDeBord')));

/***** Apps ****/
const InformationsSurLeSite = Loadable(lazy(() => import('../views/apps/informationSurSite/InformationsSurLeSite')));
const Settings = Loadable(lazy(() => import('../views/apps/settings/Settings')));
const ConfigurationsPHP = Loadable(lazy(() => import('../views/apps/configurations/Configurations')));
const SqlInjection = Loadable(lazy(() => import('../views/apps/sqlInjection/SqlInjection')));
const SqlInjectionIA = Loadable(lazy(() => import('../views/apps/sqlinjectionIA/SqlInjectionIa')));
const PasswordValidation  = Loadable(lazy(() => import('../views/apps/passwordValidation/PasswordValidation')));
const EmailCheck  = Loadable(lazy(() => import('../views/apps/emailCheck/EmailCheck')));
const BanAutomatique = Loadable(lazy(() => import('../views/apps/banAutomatique/BanList')));
const RobotsDetection = Loadable(lazy(() => import('../views/apps/robotDetection/RobotsDetection')));
const SusVisitors = Loadable(lazy(() => import('../views/apps/susVisitors/SusVisitors')));
const LogEntry = Loadable(lazy(() => import('../views/apps/logEntry/LogEntry')));
const LogEntryIa = Loadable(lazy(() => import('../views/apps/logEntryIA/LogEntryIa')));
const JiraTicket = Loadable(lazy(() => import('../views/apps/jiraTicket/JiraTicket')));
const ListTickets=Loadable(lazy(() => import('../views/apps/jiraTicket/ListTickets')));
const VerifyEmail=Loadable(lazy(() => import('../views/auth/VerifyEmail')));
const SiteList = Loadable(lazy(() => import('../views/apps/sites/SiteList')));

const ExposedServices = Loadable(lazy(() => import('../views/apps/exposedServices/ExposedServices')));
const Trafic = Loadable(lazy(() => import('../views/apps/trafic/Trafic')));
const UserValidation = Loadable(lazy(() => import('../views/apps/userValidation/UserValidation')));
const SiteValidation = Loadable(lazy(() => import('../views/apps/siteValidation/SiteValidation')));
const ParametresAdmin = Loadable(lazy(() => import('../views/apps/parametreAdmin/ParametresAdminList')));
const PatternAdmin = Loadable(lazy(() => import('../views/apps/parametreAdmin/PatternAdmin')));
const Profile = Loadable(lazy(() => import('../views/auth/Profile')));
const ChangePassword=Loadable(lazy(() => import('../views/auth/ChangePassword')));
const ResetPassword=Loadable(lazy(() => import('../views/auth/ResetPassword')));






/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));


const createThemeRoutesWithLang = (lang) => [
  {
    path: `/`, // Préfixe chaque route avec le code de langue
    element: <FullLayout />,
    children: [
      { index: true, element: <Navigate to={`/${lang}/dashboard`} replace /> },
      { path: `${lang}/dashboard`, name: 'Dashboard', element: <TableauDeBord /> },
      { path: `${lang}/site-information`, name: 'Information', element: <InformationsSurLeSite /> },
      { path: `${lang}/php-configuration`, name: 'Configuration', element: <ConfigurationsPHP /> },
      { path: `${lang}/cyber-threat-modules/password-check`, name: 'Password Check', element: <PasswordValidation /> },
      { path: `${lang}/cyber-threat-modules/email-check`, name: 'Email Check', element: <EmailCheck /> },
      { path: `${lang}/options/settings`, name: 'Settings', element: <Settings /> },
      { path: `${lang}/security-modules/sql-xss-injection`, name: 'SQL and XSS Injection', element: <SqlInjection /> },
      { path: `${lang}/security-modules/sqli-detection-by-ai`, name: 'SQL Injection AI', element: <SqlInjectionIA /> },
      { path: `${lang}/security-modules/ip-management`, name: 'Automatic Ban', element: <BanAutomatique /> },
      { path: `${lang}/cyber-threat-modules/service-check`, name: 'Exposed Services', element: <ExposedServices /> },
      { path: `${lang}/security-modules/bot-detection`, name: 'Bot Detection', element: <RobotsDetection /> },
      { path: `${lang}/security-modules/threat-log`, name: 'Threat Log', element: <LogEntry /> },
      { path: `${lang}/security-modules/threat-log-ai`, name: 'Threat Log AI', element: <LogEntryIa /> },
      { path: `${lang}/security-modules/suspicious-visitors`, name: 'Suspicious Visitors', element: <SusVisitors /> },
      { path: `${lang}/apps/traffic`, name: 'Traffic', element: <Trafic /> },
      { path: `${lang}/apps/user-verification`, name: 'User Verification', element: <UserValidation /> },
      { path: `${lang}/apps/site-verification`, name: 'Site Verification', element: <SiteValidation /> },
      { path: `${lang}/settings`, name: 'Admin Settings', element: <ParametresAdmin /> },
      { path: `${lang}/patterns`, name: 'Admin Pattern', element: <PatternAdmin /> },

      { path: `${lang}/auth/profile`, name: 'profile',  element: <Profile /> },
      { path: `${lang}/technical-support/open-ticket`, name: 'Support technique',  element: <JiraTicket /> },
      { path: `${lang}/technical-support/tickets-list`, name: 'Tickets list',  element: <ListTickets /> },
      { path: `${lang}/options/sites-list`, name: 'Sites list',  element: <SiteList /> },

  
  
      
      { path: `*`, element: <Navigate to={`/${lang}/dashboard`} replace /> },
    ],
  },
  {
    path: `/${lang}/auth`, // Utilisez la variable lang pour construire le chemin
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: `*`, element: <Navigate to={`${lang}/auth/loginformik`} replace /> },
      { path: `registerformik`, element: (
      // <GoogleReCaptchaProvider reCaptchaKey="6Lf4UQslAAAAAEwalsGDY-JAMhi6-S7HyC9TzZIU">
      <GoogleReCaptchaProvider reCaptchaKey="6LdMjxMmAAAAAC9iyQnNbkG1NwjQH10mFz2IfP9R">
      <RegisterFormik />
      </GoogleReCaptchaProvider>)
     },
      { path: `loginformik`, element:(
      // <GoogleReCaptchaProvider reCaptchaKey="6Lf4UQslAAAAAEwalsGDY-JAMhi6-S7HyC9TzZIU">
      <GoogleReCaptchaProvider reCaptchaKey="6LdMjxMmAAAAAC9iyQnNbkG1NwjQH10mFz2IfP9R">
      <LoginFormik /> 
      </GoogleReCaptchaProvider>)},
     { path: 'verifyEmail', element: <VerifyEmail /> },
     { path: 'ResetPassword', element: <ResetPassword /> },
     { path: 'reset-password/:token', element: <ChangePassword /> },
    ],
  },
];



export default createThemeRoutesWithLang;