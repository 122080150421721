import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Axios } from '../../../config';


export const addBan = createAsyncThunk('ban/addBan', async (newBanned,thunkAPI) => {
  
  try {
    const response = await Axios.post(`/bannedIp/ban`, JSON.stringify(newBanned), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    // console.log('data', response);
  
    const { data } = response; // Utilisez la déstructuration pour extraire la propriété `data`
    return { ...data, newBanned };
  } catch (e) {
    if (e.response.status === 409) {
      return thunkAPI.rejectWithValue(e.response.data);
    } else {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue({ message: 'Server Error' });
    }
  }
  
});
export const addWhiteIp= createAsyncThunk('ban/addWhite', async (newWhiteIp,thunkAPI) => {
  
  try {
    const response = await Axios.post(`/bannedIp/addwhitelist`, JSON.stringify(newWhiteIp), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    // console.log('data', response);
  
    const { data } = response; // Utilisez la déstructuration pour extraire la propriété `data`
    return { ...data, newWhiteIp };
  } catch (e) {
    if (e.response.status === 409) {
      return thunkAPI.rejectWithValue(e.response.data);
    } else {
      console.log('Error', e.response.data);
      return thunkAPI.rejectWithValue({ message: 'Server Error' });
    }
  }
  
});
export const getBan = createAsyncThunk('fetch/fetchBan', async ({siteId,userRole}) => {
  try {
    let res = await Axios.get(`/bannedIp/banList?siteId=${siteId}&userRole=${userRole}`);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const getBanAdmin = createAsyncThunk('fetch/fetchBanAdmin', async ({siteId,userRole}) => {
  try {
    let url = `/bannedIp/banListAdmin?userRole=${userRole}`;
    if (userRole === 'Admin' && siteId) {
      url += `&siteId=${siteId}`;}
   
      
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const getWhiteAdmin = createAsyncThunk('fetch/fetchWhiteAdmin', async ({siteId,userRole}) => {
  try {
    let url = `/bannedIp/whiteListAdmin?userRole=${userRole}`;
    if (userRole === 'Admin' && siteId) {
      url += `&siteId=${siteId}`;}
   
      
    let res = await Axios.get(url);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const getWhite = createAsyncThunk('fetch/fetchWhiteIp', async ({siteId,userRole}) => {
  try {
    let res = await Axios.get(`/bannedIp/whiteList?siteId=${siteId}&userRole=${userRole}`);
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});
export const banToWhitelist = createAsyncThunk('ban/banToWhitelist', async (payload, thunkAPI) => {
  try {
    const response = await Axios.post(`/bannedIp/addbantowhite`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return { ...response.data, ipToMove: payload };
  } catch (e) {
    console.log('Error', e.response.data);
    return thunkAPI.rejectWithValue(e.response?.data?.message || 'Server Error');
  }
});

export const whiteToBanList = createAsyncThunk('ban/whiteToBanList', async (payload, thunkAPI) => {
  try {
    const response = await Axios.post(`/bannedIp/addwhitetoban`, JSON.stringify(payload), {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    return { ...response.data, ipToMove: payload };
  } catch (e) {
    console.log('Error', e.response.data);
    return thunkAPI.rejectWithValue(e.response?.data?.message || 'Server Error');
  }
});


export const deleteBan = createAsyncThunk('delete/ban', async ({ ip, siteId }) => {
  try {
    let res = await Axios.delete(`/bannedIp/deletebanIp`, { data: { ip, siteId } });
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});

export const deleteWhiteIp = createAsyncThunk('delete/', async ({ ip, siteId }) => {
  try {
    let res = await Axios.delete(`/bannedIp/deletewhiteIp`, { data: { ip, siteId } });
    return res.data;
  } catch (error) {
    throw Error(error.response.data);
  }
});


export const banAutomatiqueSlice = createSlice({
    name: 'ban',
    initialState: {
      isSuccess: false,
      isError: false,
      isFetching: false,
      isSuccessAdd: false,
      isErrorAdd: false,
      isFetchingAdd: false,
      isSuccessAddWhite: false,
      isFetchingAddToWhite:false,
      isSuccessAddToWhite:false,
      isErrorAddToWhite:false,
      isSuccessGetBan:false,
      isErrorAddWhite: false,
      isFetchingAddWhite: false,
      isSuccessAddToBan:false,
      isErrorAddToBan:false,
      isFetchingAddToBan:false,
      messageError:'',
      ban: [], // initialiser ban comme un tableau vide
      whiteIp:[],
      isFetchingWhite:false,
      isFetchingBan:false,
      isFetchingBanAdmin:false,
      isErrorAdmin:false,
      isSuccessGetBanAdmin:false,

      banAdmin: [], // initialiser ban comme un tableau vide
      isFetchingWhiteAdmin:false,
      isErrorWhiteAdmin:false,
      isSuccessGetWhiteAdmin:false,

      whiteAdmin: [], // initialiser ban comme un tableau vide

    },
    reducers: {
      clearBanData: (state) => {
        Object.assign(state, {
          isSuccess: false,
      isError: false,
      isFetching: false,
      isSuccessAdd: false,
      isErrorAdd: false,
      isFetchingAdd: false,
      isSuccessAddWhite: false,
      isSuccessAddToWhite:false,
      isSuccessGetBan:false,
      isErrorAddWhite: false,
      isSuccessAddToBan:false,
      isErrorAddToBan:false,
      isFetchingAddToBan:false,
      isFetchingAddWhite: false,
      isFetchingWhite:false,
      isFetchingBan:false,
      isFetchingBanAdmin:false,
      isErrorAdmin:false,
      isSuccessGetBanAdmin:false,
     

      banAdmin: [], // initialiser ban comme un tableau vide
      isFetchingWhiteAdmin:false,
      isErrorWhiteAdmin:false,
      isSuccessGetWhiteAdmin:false,

      whiteAdmin: [], // initialiser ban comme un tableau vide

      messageError:'',
      ban: [], // initialiser ban comme un tableau vide
      whiteIp:[]
        });}
    },
    extraReducers: (builder) => {
      builder
        .addCase(addBan.pending, (state) => {
          state.isFetchingAdd = true;
          state.isSuccessAddToWhite = false;
          state.isErrorAdd = false;
          state.isSuccessAdd = false;})
      
        .addCase(addBan.fulfilled, (state, { payload }) => {
          state.isFetchingAdd = false;
          state.ban = payload;
         state.isSuccessAdd=true;
         state.isSuccessAddToWhite = false;
         state.isErrorAdd=false
          })
          .addCase(addBan.rejected, (state, { payload }) => {
            state.isFetchingAdd = false;
            state.isErrorAdd = true;
            state.isSuccessAdd = false;
            state.messageError=payload.message
          })
          .addCase(addWhiteIp.pending, (state) => {
            state.isFetchingAddWhite = true;
            state.isSuccessAddToWhite = false;
            state.isErrorAddWhite = false;
            state.isSuccessAddWhite = false;})
        
          .addCase(addWhiteIp.fulfilled, (state, { payload }) => {
            state.isFetchingAddWhite = false;
            state.whiteIp = payload;
           state.isSuccessAddWhite=true;
           state.isErrorAddWhite=false
            })
            .addCase(addWhiteIp.rejected, (state, { payload }) => {
              state.isFetchingAddWhite = false;
              state.isErrorAddWhite = true;
              state.isSuccessAddWhite = false;
              state.messageError=payload.message
            })
            .addCase(banToWhitelist.pending, (state) => {
              state.isFetchingAddToWhite = true;
              state.isErrorAddToWhite = false;
              state.isSuccessAddToWhite = false;
            })
            .addCase(banToWhitelist.fulfilled, (state, { payload }) => {
              state.isFetchingAddToWhite = false;
              state.whiteIp = payload;
              state.isSuccessAddToWhite = true;
              state.isErrorAddToWhite = false;
            })
            .addCase(banToWhitelist.rejected, (state, { payload }) => {
              state.isFetchingAddToWhite = false;
              state.isErrorAddToWhite = true;
              state.isSuccessAddToWhite = false;
              state.messageError = payload.message;
            })
            .addCase(whiteToBanList.pending, (state) => {
              state.isFetchingAddToBan = true;
              state.isErrorAddToBan = false;
              state.isSuccessAddToWhite = false;
              state.isSuccessAddToBan = false;
            })
            .addCase(whiteToBanList.fulfilled, (state, { payload }) => {
              state.isFetchingAddToBan = false;
              state.ban = payload;
              state.isSuccessAddToBan = true;
              state.isErrorAddToBan = false;
            })
            .addCase(whiteToBanList.rejected, (state, { payload }) => {
              state.isFetchingAddToBan = false;
              state.isErrorAddToBan = true;
              state.isSuccessAddToBan = false;
              state.messageError = payload.message;
            })
          .addCase(getBan.pending, (state) => {
            state.isFetchingBan = true;
            state.isSuccessAddToWhite = false;
            state.isError = false;
            state.isSuccessGetBan = false;
          state.isSuccessAdd=false;})
        
          .addCase(getBan.fulfilled, (state, { payload }) => {
            state.isFetchingBan = false;
            state.ban = payload;
           state.isSuccessGetBan=true;
           state.isError=false
            })
            .addCase(getBan.rejected, (state, { payload }) => {
              state.isFetchingBan = false;
              state.isError = true;
              state.isSuccessGetBan = false;
            })
          .addCase(getBanAdmin.pending, (state) => {
            state.isFetchingBanAdmin = true;
            state.isSuccessAddToWhite = false;
            state.isErrorAdmin = false;
            state.isSuccessGetBanAdmin = false;
          state.isSuccessAdd=false;})
        
          .addCase(getBanAdmin.fulfilled, (state, { payload }) => {
            state.isFetchingBanAdmin = false;
            state.banAdmin = payload;
           state.isSuccessGetBanAdmin=true;
           state.isErrorAdmin=false
            })
            .addCase(getBanAdmin.rejected, (state, { payload }) => {
              state.isFetchingBanAdmin = false;
              state.isErrorAdmin = true;
              state.isSuccessGetBanAdmin = false;
            })
          .addCase(getWhiteAdmin.pending, (state) => {
            state.isFetchingWhiteAdmin = true;
            state.isSuccessAddToWhite = false;
            state.isErrorWhiteAdmin = false;
            state.isSuccessGetWhiteAdmin = false;
          state.isSuccessAdd=false;})
        
          .addCase(getWhiteAdmin.fulfilled, (state, { payload }) => {
            state.isFetchingWhiteAdmin = false;
            state.whiteAdmin = payload;
           state.isSuccessGetWhiteAdmin=true;
           state.isErrorWhiteAdmin=false
            })
            .addCase(getWhiteAdmin.rejected, (state, { payload }) => {
              state.isFetchingWhiteAdmin = false;
              state.isErrorWhiteAdmin = true;
              state.isSuccessGetWhiteAdmin = false;
            })
          .addCase(getWhite.pending, (state) => {
            state.isFetchingWhite = true;
            state.isSuccessAddToWhite = false;
            state.isError = false;
            state.isSuccess = false;})
        
          .addCase(getWhite.fulfilled, (state, { payload }) => {
            state.isFetchingWhite = false;
            state.whiteIp = payload;
           state.isSuccess=true;
           state.isSuccessAddToWhite = false;

           state.isError=false
            })
            .addCase(getWhite.rejected, (state, { payload }) => {
              state.isFetchingWhite = false;
              state.isError = true;
              state.isSuccess = false;
            })
            .addCase(deleteBan.pending, (state) => {
                state.isFetching = true;
                state.isSuccessAddToWhite = false;
                state.isError = false;
                state.isSuccess = false;})
            
              .addCase(deleteBan.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.ban = payload;
               state.isSuccess=true;
               state.isError=false
                })
                .addCase(deleteBan.rejected, (state, { payload }) => {
                  state.isFetching = false;
                  state.isError = true;
                  state.isSuccess = false;
                })
            .addCase(deleteWhiteIp.pending, (state) => {
                state.isFetching = true;
                state.isSuccessAddToWhite = false;
                state.isError = false;
                state.isSuccess = false;})
            
              .addCase(deleteWhiteIp.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.whiteIp = payload;
               state.isSuccess=true;
               state.isError=false
                })
                .addCase(deleteWhiteIp.rejected, (state, { payload }) => {
                  state.isFetching = false;
                  state.isError = true;
                  state.isSuccess = false;
                })
         
        }})
    
        export const { clearBanData } = banAutomatiqueSlice.actions;

export default banAutomatiqueSlice.reducer;