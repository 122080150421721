import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useRoutes, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import createThemeRoutesWithLang from './routes/Router';
import Loader from './layouts/loader/Loader';
import { clearStoreAndLogout, logout } from './store/apps/user/userSlice';
import Hotjar from './Hotjar';
import ReactGA from 'react-ga4';

const App = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isRTL = currentLanguage === 'ar';  
  const location = useLocation();
 const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthAuth } = useSelector((state) => state.userReducer);
  ReactGA.initialize('G-XX2R3X4BMR');


  useEffect(() => {
    const currentPath = location.pathname;
    const currentLang = i18n.language;
   // Extract the part of the path that comes after the language segment
    const pathWithoutLang = currentPath.replace(/^\/[a-z]{2}(?:-[A-Z]{2})?\//, '/');
    // Construct the new path with the selected language
    const newPath = `/${currentLang}${pathWithoutLang}`;
   // Only navigate if the new path is different from the current path
    if (newPath !== currentPath) {
      navigate(newPath, { replace: true });
    }
  }, [location, i18n.language, navigate]);
  
  useEffect(() => {
    const token = Cookies.get('token');
    const currentPath = window.location.pathname;
    console.log(currentPath)
    const currentLang = i18n.language; // Obtenez la langue courante à partir de i18n
    const isAuthPage = currentPath.includes(`/auth/registerFormik`) || currentPath.includes(`/auth/loginFormik`)|| currentPath.includes(`/auth/ResetPassword`)|| currentPath.includes(`/auth/reset-password`);
    if ( !token && !isAuthAuth && !isAuthPage) {
      dispatch(clearStoreAndLogout())
      navigate(`/${currentLang}/auth/loginformik`); // Utilisez la langue courante pour la redirection
    }
  }, [isAuthAuth, navigate,i18n.language,dispatch]);

  const routes = useRoutes(createThemeRoutesWithLang(i18n.language));
  // console.log(routes)

  return (
    <Suspense fallback={<Loader />}>
    <div className={`${isRTL ? 'rtl' : 'ltr'}`} dir={isRTL ? 'rtl' : 'ltr'}>
      <Hotjar/>
      <ThemeSelector />

      {routes}
    </div></Suspense>
  );
};

export default App;