import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Axios } from '../../../config';

// Combines all fetches into one if possible
export const fetchAllInformation = createAsyncThunk('info/fetchAll', async ({ siteId }, { rejectWithValue }) => {
  try {
    const res = await Axios.get(`statis/info?siteId=${siteId}`);
    return res.data; // Assuming data contains MEMORY_INFO, STORAGE_INFO, EXTENSION, and SERVER_INFO
  } catch (error) {
    return rejectWithValue(error.response?.data || "Unknown error occurred");
  }
});

const initialState = {
  isFetchingAllData: false,
  isSuccessAllData: false,
  isErrorAllData: false,
  errorMessageAllData: '',
  configurationInf:null,
  info: null,
  phpExt: [],
  memoryInfo: {
    free: null,
    total: null
  },
  storageInfoo: {
    free: null,
    total: null
  }
};

export const informationSlice = createSlice({
  name: 'information',
  initialState,
  reducers: {
    clearInformationsData: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllInformation.pending, (state) => {
        state.isFetchingAllData = true;
      })
      .addCase(fetchAllInformation.fulfilled, (state, { payload }) => {
        state.info = payload?.SERVER_INFO;
        state.configurationInf=payload?.PHP_INFO;
        state.phpExt = payload?.EXTENSION?.filter((ext) => !ext.startsWith('[') && ext.trim() !== '');
        state.memoryInfo = payload?.MEMORY_INFO;
        state.storageInfoo = payload?.STORAGE_INFO;
        state.isFetchingAllData = false;
        state.isSuccessAllData = true;
      })
      .addCase(fetchAllInformation.rejected, (state, { payload }) => {
        state.isFetchingAllData = false;
        state.isSuccessAllData = false;
        state.configurationInf=null;
        state.isErrorAllData = true;
        state.errorMessageAllData = payload;
        state.info = null;
        state.phpExt = [];
        state.memoryInfo = { free: null, total: null }; // Réinitialisez les informations de mémoire
        state.storageInfoo = { free: null, total: null }; // Réinitialisez les informations de stockage
      
      });
  },
});

export const { clearInformationsData } = informationSlice.actions;
export default informationSlice.reducer;
